@import "../../index.scss";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 80px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5em 0;

  @media (max-width: 992px) {
    max-width: 100%;
    top: unset;
    bottom: 0;
    height: 64px;
    flex-direction: row;
    justify-content: center;
    z-index: 1000;
  }

  .logo,
  .flex {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .nav_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2em;
    list-style: none;

    @media (max-width: 992px) {
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
    }

    .nav_items {
      a {
        text-decoration: none;

        &.active {
          img {
            opacity: 1;
            transition: 300ms all ease-in-out;
          }
        }

        img {
          width: 24px;
          opacity: 0.5;
          transition: 300ms all ease-in-out;
        }
      }
    }
  }
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
}