@import "../../index.scss";

.details_page {
  .block {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-gap: 20px;
    height: 500px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      height: auto;
    }

    .image {
      width: 100%;
      height: 500px;
      position: relative;

      @media (max-width: 768px) {
        height: auto;
      }

      .video_nav_btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3em;
        height: 3em;
        cursor: pointer;
      }

      img,
      span,
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media (max-width: 768px) {
          object-fit: contain;
        }
      }
    }

    .nft_details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-gap: 1em;

      .form_input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ebebeb;
        border-radius: 5px;
        padding: 10px 1em;
        margin-bottom: 1em;

        p {
          background: transparent;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.04em;
        }
      }

      .content {
        .title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .description {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        > div {
          display: flex;
          align-items: center;
          grid-gap: 1.25em;
          margin-top: 1em;
        }
      }

      .bid_details {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          display: flex;
          flex-direction: column;
          grid-gap: 5px;
        }

        > div:nth-child(1) {
          align-items: flex-start;
        }
        > div:nth-child(2) {
          align-items: flex-end;
        }
      }

      .content_footer {
        text-align: center;
        button {
          margin-bottom: 1em;
          width: 100%;
        }
      }
    }
  }

  .nft_activity {
    margin: 2em 0;

    .history_details {
      background: #f7f7f7;
      border-radius: 10px;
      padding: 10px 20px;
      margin-top: 1em;
      display: flex;
      flex-direction: column-reverse;

      > .flex_gap {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;

        > p {
          display: flex;
          align-items: center;
        }
      }

      > .flex_gap:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }

      .flex {
        display: grid;
        place-items: center;
      }
    }
  }
}

.grid_center {
  height: 400px;
  display: grid;
  place-items: center;
}

.bid-input {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
}