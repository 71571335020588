@import "../../index.scss";

.marketplace_screen {
  .filter_auctions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    margin-bottom: 1em;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    .range {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 1em;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 1em;

        > p {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        section {
          background: #ebebeb;
          border-radius: 50px;
          height: 10px;
          position: relative;
          width: 100%;

          p {
            border-radius: 50px;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            height: 100%;
          }
        }

        input {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          -webkit-appearance: none;
          border-radius: 50px;
          height: 10px;
          width: 100%;
          border: none;
          outline: none;
          background: transparent;
          z-index: 1;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            cursor: pointer;
            background: #f79420;
            box-shadow: 0px 3px 20px rgba(247, 148, 32, 0.1);
            height: 1.5em;
            width: 1.5em;
            border-radius: 50%;
            border: 1px solid #fff;
          }
        }
      }
      p {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.03em;
        white-space: nowrap;
        color: #0b264a;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;

      .dropdown {
        background: #ebebeb;
        border-radius: 5px;
        padding: 10px 1em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;

        .dropdown_content {
          position: absolute;
          top: 110%;
          left: 0;
          width: 100%;
          background: #ebebeb;
          border-radius: 5px;
          padding: 10px 1em;
          z-index: 1000;

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
