@import "../../index.scss";

.live_auction_card_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 1.25em;
  margin-bottom: 2em;
  @media (min-width: 992px) {
    display: flex;
    flex-wrap: wrap;
    .card {
      width: 32%;
    }
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.center {
  display: grid;
  place-items: center;
  min-height: 300px;
}
